import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const router = new VueRouter({
  routes: [
    { path: '/', redirect: '/dashboard', },
    { path: '/dashboard', name: 'dashboard', component: () => import('@/views/dashboard') }
  ]
});
export default router
